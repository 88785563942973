import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import ContactForm from '../components/TermosForm'
import { Container } from '../components/styles/Layout.styles'
import { 
  styledContact,
  header,
  contactForm,
  termosBox
} from '../components/styles/Contact.styles'
import { validateRequiredEmail } from '../utils/validations';

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
];

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledContact}>
      <Container>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={header}
        >
          <Column
            mt={[0, 0, 32, 32]}
            style={{
              margin: '0 auto'
            }}
          >
            <div css={contactForm}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <ContactForm
                  fields={fields}
                  formId={4290}
                  submitText='Enviar Mensagem'
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Entre em contato | HostDime Brasil' 
        description='Nossos desenvolvedores de negócios e analistas técnicos a um clique de distância. Compartilhe suas dúvidas sobre nossos produtos e receba o contato de nosso time.' 
      />
      <PageTemplate
        title='Contato'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
